import { Component, HostListener } from '@angular/core';
import { initFlowbite } from 'flowbite';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  isDropdownOpen = false;
  adminEmail:string = localStorage.getItem('admin_email') || '';
  ngOnInit(){
    initFlowbite();
    this.isDropdownOpen = false;
  }
  
    /**
     * Toggles dropdown
     * @param event 
     */
    toggleDropdown(event: MouseEvent) 
    {
      event.stopPropagation();
      this.isDropdownOpen = !this.isDropdownOpen;
    }
    @HostListener('document:click', ['$event'])
    handleClickOutside(event: Event) {
      const target = event.target as HTMLElement;
      if (!target.closest('#user-menu-button') && !target.closest('#dropdown')) {
        this.isDropdownOpen = false;
      }
    }
}
